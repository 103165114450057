class MileageMoneyConfirm {
  $rootScope:                    any;
  $filter:                       any;
  $location:                     any;
  $translate:                    any;
  api:                           any;
  notification:                  any;
  loaderFactory:                 any;
  errFactory:                    any;
  mileageMoneyFactory:           any;
  timeTrackingValidationService: any;

  prefilledMileageData: any;
  mileageData:          any;
  assignment:           any;
  constructor ($rootScope, $location, $filter, $translate, API, Notification, LoaderFactory, ErrFactory, MileageMoneyFactory, TimeTrackingValidationService) {
    Object.defineProperties(this, {
      $rootScope:                    { value: $rootScope                    },
      $filter:                       { value: $filter                       },
      $location:                     { value: $location                     },
      $translate:                    { value: $translate                    },
      api:                           { value: API                           },
      notification:                  { value: Notification                  },
      loaderFactory:                 { value: LoaderFactory                 },
      errFactory:                    { value: ErrFactory                    },
      mileageMoneyFactory:           { value: MileageMoneyFactory           },
      timeTrackingValidationService: { value: TimeTrackingValidationService }
    });

    if ($rootScope.mileageData) this.prepareMileage($rootScope.mileageData);
    else $location.path(`/`);
  }

  private prepareMileage(mileageData): void {
    this.prefilledMileageData = new this.mileageMoneyFactory(mileageData);
    this.mileageData          = new this.mileageMoneyFactory(mileageData);
    this.assignment           = mileageData.assignment;
    this.createFirstDay();
  }

  validateDistance(workDay): void {
    if (!workDay.amountOfKm) workDay.amountOfKm = 1;
    this.validate();
  }

  createFirstDay(): void {
    this.mileageData.workDays = [];
    this.mileageData.workDays.push(new this.mileageMoneyFactory.MileageMoneyWorkDay(this.prefilledMileageData.workDays[0].toJSON()));
  }

  copy(wd): void {
    let copyDayIndex      = this.mileageData.workDays.findIndex(         mwd => mwd.date.getTime() === wd.date.getTime());
    let prefilledDayIndex = this.prefilledMileageData.workDays.findIndex(pwd => pwd.date.getTime() === wd.date.getTime());
    let validDays         = this.prefilledMileageData.workDays.filter(pwd => !this.mileageData.workDays.find(wd => wd.date.getTime() === pwd.date.getTime()));
    if (this.prefilledMileageData.workDays.length > prefilledDayIndex && validDays.length) {
      for (let i = 0; i < validDays.length; i++) {
        let valid = validDays[i].date.getTime() > this.mileageData.workDays[copyDayIndex].date.getTime();
        if (valid) {
          let day  = this.mileageData.workDays[copyDayIndex].toJSON();
          day.date = this.$filter('date')(validDays[i].date, 'yyyy-MM-dd');
          for (let k = copyDayIndex+1; k < this.prefilledMileageData.workDays.length; k++) {
            if (!this.mileageData.workDays[k]) return this.mileageData.workDays.push(new this.mileageMoneyFactory.MileageMoneyWorkDay(day));
            else if (this.mileageData.workDays[k]?.date.getTime() > validDays[i].date.getTime()) return this.mileageData.workDays.splice(k, 0, new this.mileageMoneyFactory.MileageMoneyWorkDay(day));
          }
          this.validate();
          return;
        }
        if (i+1 === validDays.length && !valid) this.notification.alert({
          title: 'note',
          desc:  'mileageMoney.noValidDaysForMileageMoney'
        });
      }
    } else this.notification.alert({
      title: 'note',
      desc:  'mileageMoney.noValidDaysForMileageMoney'
    });
  }

  delete(index: number): void {
    this.mileageData.workDays.splice(index, 1);
    this.validate();
  }

  totalKm(): number {
    return Math.round((this.mileageData?.workDays?.reduce((sum, val) => sum = sum + val.amountOfKm, 0) + Number.EPSILON) * 100) / 100;
  }

  prepareSubmit(): Promise<any> {
    let errors = [...new Set(this.mileageData.workDays.reduce((sum, val) => {
      sum = [...sum, ...(val?.errorsList || [])];
      return sum;
    }, []))];
    if (errors?.length) return this.$translate(errors).then(t => this.notification.alert({
      title: 'errors.checkHighlightedFields',
      desc:  Object.values(t).map(e => `- ${e}`).join('\n')
    }));

    let data = {
      start_date:       this.$filter('date')(this.mileageData.startDate, 'yyyy-MM-dd'),
      end_date:         this.$filter('date')(this.mileageData.endDate,   'yyyy-MM-dd'),
      assignment_id:    this.assignment.id,
      ebs_data_id:      this.mileageData.ebsDataId,
      license_plate_id: this.mileageData.licensePlateId,
      work_days:        this.mileageData.workDays.map(wd => ({
        date:                      this.$filter('date')(wd.date, 'yyyy-MM-dd'),
        amount_of_km:              wd.amountOfKm,
        assignment_address:        wd.assignmentAddress.trim(),
        external_employee_address: wd.externalEmployeeAddress.trim()
      }))
    };

    this.submit(data);
  }

  private submit(data, skipValidation: boolean = null): Promise<any> {
    if (skipValidation) data.confirm_report_type_duplicate = true;

    this.loaderFactory.show();
    return this.api.submitMileageMoney(data)
    .then(() => {
      this.$rootScope.mileageData = null;
      return this.notification.alert({
        title: 'activityReport.successfulTitle',
        desc:  'mileageMoney.successfulMessage'
      }, () => this.$location.path('/'));
    })
    .catch((err) => {
      if (err?.response?.data?.errors[0]?.code === 'duplicates_mileage_report_within_type' ||
          err?.response?.data?.errors[0]?.code === 'duplicates_both_mileage_report_types') this.confirmDuplicate(err, data);
      else if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  private confirmDuplicate(err, data): Promise<any> {
    return this.$translate('mileageMoney.confirmDuplicate')
    .then(t => this.notification.confirm_p({
      title: 'confirm',
      desc: err.message + '\n\n' + Object.values(t).join(''),
      cancelButton: 'no',
      button: 'yes'
    })
    .then(res => {
      if (res === 2) this.submit(data, true);
    }));
  }

  back(): void {
    this.$location.path('/mm-new');
  }

  validate(): void {
    this.mileageData.workDays.forEach(wd => {
      wd.errors     = [];
      wd.errorsList = [];
    });
    this.timeTrackingValidationService.validateMileageReport(this.mileageData.workDays, this.prefilledMileageData.workDays);
    this.mileageData.workDays.forEach(wd => wd.errorsList = [...new Set(wd.errors.map(e => e.message))]);
  }

  dayError(workDay) {
    return workDay?.errors?.find(e => e.dayError);
  }

}

window.app.component('mileageMoneyConfirm', {
  template: require('scripts/components/mileage-money/mm-confirm/mm-confirm.html'),
  controller: ['$rootScope', '$location', '$filter', '$translate', 'API', 'Notification', 'LoaderFactory', 'ErrFactory', 'MileageMoneyFactory', 'TimeTrackingValidationService', MileageMoneyConfirm]
});
