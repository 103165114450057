class MileageMoneyListItem {
  mileageMoney: any;
  constructor() { }

  getStartOfWeek(date: Date): Date {
    return Weeks.getStartOfWeek(date);
  }
}

window.app.component('mileageMoneyListItem', {
  template: require('scripts/components/mileage-money/mm-list/mm-list-item/mm-list-item.html'),
  controller: [MileageMoneyListItem],
  bindings: {
    mileageMoney: '<'
  }
});
