class MileageMoneySubmitted {
  $scope:              any;
  $rootScope:          any;
  $routeParams:        any;
  $location:           any;
  api:                 any;
  appFactory:          any;
  loaderFactory:       any;
  errFactory:          any;
  mileageMoneyService: any;
  mileageMoneyFactory: any;

  mileageMoney: any;
  constructor ($scope, $rootScope, $routeParams, $location, API, AppFactory, LoaderFactory, ErrFactory, MileageMoneyService, MileageMoneyFactory) {
    Object.defineProperties(this, {
      $scope:              { value: $scope              },
      $rootScope:          { value: $rootScope          },
      $routeParams:        { value: $routeParams        },
      $location:           { value: $location           },
      api:                 { value: API                 },
      appFactory:          { value: AppFactory          },
      loaderFactory:       { value: LoaderFactory       },
      errFactory:          { value: ErrFactory          },
      mileageMoneyService: { value: MileageMoneyService },
      mileageMoneyFactory: { value: MileageMoneyFactory }
    });

    let id = parseInt($routeParams.id);
    if (id) this.loadReport(id);
    else $location.path('/');
  }

  private loadReport(id: number): Promise<any> {
    this.loaderFactory.show();
    return this.mileageMoneyFactory.getById(id)
    .then(mm => {
      this.mileageMoney = new this.mileageMoneyFactory.MileageMoneySubmitted(mm);
      this.loaderFactory.hide();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  totalKm(): number {
    return this.mileageMoney?.workDays?.length ? Math.round((this.mileageMoney?.workDays?.reduce((sum, val) => sum = sum + val.amountOfKm, 0) + Number.EPSILON) * 100) / 100 : 0;
  }

  back(): void {
    this.$location.path('/mm-list');
  }

  openSplitReport(id: number): void {
    this.loaderFactory.show();
    if (this.$rootScope.submitedMMs.find((item) => item.id === id)) this.$location.path(`/mm-submitted/${id}`);
    else this.mileageMoneyService.getMileageMoneyById(id)
    .then(mm => mm.save())
    .then(mm => this.$rootScope.submitedMMs.push(mm))
    .then(() => {
      this.$location.path(`/mm-submitted/${id}`);
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
      this.loaderFactory.hide();
    });
  }

}

window.app.component('mileageMoneySubmitted', {
  template: require('scripts/components/mileage-money/mm-submitted/mm-submitted.html'),
  controller: ['$scope', '$rootScope', '$routeParams', '$location', 'API', 'AppFactory', 'LoaderFactory', 'ErrFactory', 'MileageMoneyService', 'MileageMoneyFactory', MileageMoneySubmitted]
});
